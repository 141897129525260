<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <servidores-bancos-dados-data-table
          :items="items"
          :loading="loading"
          @status:changed="getServidores"
          @rechargeTable="getServidores"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServidoresBancosDadosGeoPerdasService from '@/services/ServidoresBancosDadosGeoPerdasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],
  components: {
    ServidoresBancosDadosDataTable: () =>
      import(
        '@/components/geo-perdas/servidores-bancos-dados/ServidoresBancosDadosDataTable'
      )
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getServidores);
  },
  methods: {
    getServidores() {
      this.loading = true;
      ServidoresBancosDadosGeoPerdasService.getServidores()
        .then((responseData) => (this.items = responseData))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
